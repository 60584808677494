import { Box, IconButton, Grid, Typography } from '@mui/material'
import {
    MeetingRoom,
    NoMeetingRoom
} from '@mui/icons-material';
import Moment from 'react-moment'

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'

type EventCardProp = {
    title: string
    address: string
    active: boolean
    uuid: string
    startDate: string
    stopDate: string
    hasAd: boolean
    hasCc: boolean
    hasLb: boolean
}

export default function EventCard(props:EventCardProp) {

    const eventLocal = (
        <Box>
            <Box sx={[mainStyle.flexRow, {paddingTop: 1, alignItems: 'flex-end', height: 120}]}>
                <Box sx={[mainStyle.flexColumn]}>
                    <Typography
                        component='span'
                        sx={{
                            marginLeft: 1,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        {props.address}
                    </Typography>
                    <Typography
                        component='span'
                        sx={{
                            marginLeft: 1,
                            color: '#fff',
                            fontFamily: 'bold, 20',
                            fontSize: 16
                        }}
                    >
                        <Moment date={props.startDate} format='DD/MM/YYYY HH:mm' /> às <Moment date={props.stopDate} format='DD/MM/YYYY HH:mm' />
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box>
            <Box sx={[mainStyle.eventCard, (props.active === true) ? mainStyle.availableEventCard : mainStyle.unavailableEventCard]}>
                <Box>
                    <Typography
                        component='span'
                        sx={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 18,
                        }}
                    >
                        {props.title}
                    </Typography>
                </Box>
                 <Box sx={[{display: 'flex', flexDirection: 'row', gap: 2 }]} aria-label='Acessibilidades disponíveis'>
                    {props.hasAd && <Typography sx={[mainStyle.accessibilityTag]} aria-label='Áudio descrição'>AD</Typography>}
                    {props.hasCc && <Typography sx={[mainStyle.accessibilityTag]} aria-label='Closed Captions'>CC</Typography>}
                    {props.hasLb && (
                        <Typography sx={[mainStyle.accessibilityTag]} aria-label='LIBRAS'>LIBRAS</Typography>
                    )}
                </Box>
                <Box sx={[mainStyle.flexRow, {height: 90}]}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            {eventLocal}
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={[mainStyle.flexRow, {justifyContent: 'center', alignItems: 'flex-end', paddingRight: 3, height: 80}]}>
                                <IconButton
                                    sx={[(props.active) ? mainStyle.primaryColor : mainStyle.secondaryColor, mainStyle.iconButton]}
                                >
                                    {(props.active === true) ? 
                                        <MeetingRoom color='inherit' sx={{fontSize: 42}} />
                                    :
                                        <NoMeetingRoom color='inherit' sx={{fontSize: 42}} />
                                    }
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
