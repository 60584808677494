import axios from 'axios'

const axiosHelp = axios.create()

axiosHelp.interceptors.request.use(
    async function(config){
        return new Promise(function (resolve, reject) {
            try {
                config.headers.Authorization = `Bearer ${process.env.REACT_APP_API_KEY}`;
                resolve(config)
            }
            catch (err) {
                reject(err)
            }
        })
    },
    function (error) {
        return Promise.reject(error)
    }
)

axiosHelp.interceptors.response.use(
    function(response) {
        return response
    },
    function (error) {
        return Promise.reject(error);
    }
)

function axiosHelper(config:any) {
	return axiosHelp(config)
}

export default axiosHelper
