import { Outlet } from 'react-router-dom'
import InstallPromptModal from './components/InstallPromptModal';

function MainWindow() {
    return (
        <>
            <InstallPromptModal />
            <Outlet />
        </>
    )
}

export default MainWindow
