import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { 
    Box,
    Link,
    Typography,
} from '@mui/material'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

// Adapters
import { getEventRoomInfo } from '../../adapters/Events.adapter'

// Interfaces
import { IEventRoom } from './Interfaces.events'

// TRIO Components
import EventRoomAccessibilityCard from '../components/EventRoomAccessibilityCard'
import Loading from '../components/Loading'
import { mainStyle } from '../../styles/Index.Styles'
import Header from '../components/Header'
import EventRoomTranslationCard from '../components/EventRoomTranslationCard'

export default function RoomsOptions() {
    const { eventId, roomId } = useParams()

    const [roomInfos, setRoomInfos] = useState<IEventRoom | undefined>(undefined)
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => { 
        if (eventId !== undefined && roomId !== undefined) {
            setIsFetching(true)
            getEventRoomInfo(eventId, roomId)
            .then((res) => {
                setRoomInfos(res.data)
            }).finally(() => {
                setIsFetching(false)
            })
        }
    }, [eventId, roomId])

    const mountRoomAccessibilityOptions = (room: IEventRoom) => {
        return <Grid2 container direction={'column'} rowGap={2}>
                <Link 
                    href={((room.available === true && room.ad_stream_link !== '') ? `/event/${room.eventId}/${room.id}/player?type=ad`  : "#")}
                    color="inherit"
                    underline='none'
                >
                    <EventRoomAccessibilityCard
                        active={room.ad_stream_link !== ''}
                        eventUuid={room.eventId}
                        roomUuid={room.id}
                        sourceLink={room.ad_stream_link}
                        type={'ad'}
                    />
                </Link>
                <Link 
                    href={((room.available === true && room.cc_stream_link !== '') ? `/event/${room.eventId}/${room.id}/player?type=cc`  : "#")}
                    color="inherit"
                    underline='none'
                >
                    <EventRoomAccessibilityCard
                        active={room.cc_stream_link !== ''}
                        eventUuid={room.eventId}
                        roomUuid={room.id}
                        sourceLink={room.ad_stream_link}
                        type={'cc'}
                    />
                </Link>
                <Link 
                    href={((room.available === true && room.lb_stream_link !== '') ? `/event/${room.eventId}/${room.id}/player?type=lb`  : "#")}
                    color="inherit"
                    underline='none'
                >
                    <EventRoomAccessibilityCard
                        active={room.lb_stream_link !== ''}
                        eventUuid={room.eventId}
                        roomUuid={room.id}
                        sourceLink={room.lb_stream_link}
                        type={'lb'}
                    />
                </Link>
        </Grid2>
    }

    const mountRoomTranslationOptions = (room: IEventRoom) => {
        if(room.translations === undefined || room.translations === null ) {
            return null
        } 
        
        const translationCards = []

        for (let lang in room.translations) {
            translationCards.push(
                <Link 
                    href={((room.available === true && room.translations[lang] !== '') ? `/event/${room.eventId}/${room.id}/player?type=translation&lang=${lang}`  : "#")}
                    color="inherit"
                    underline='none'
                    key={`translation-card-${lang}`}
                >
                    <EventRoomTranslationCard
                        active={true}
                        eventUuid={room.eventId}
                        roomUuid={room.id}
                        sourceLink={room.translations[lang]}
                        language={lang}
                    />
                </Link>)
        }

        return <Grid2 container direction={'column'} rowGap={2}>
            {translationCards}
        </Grid2>
        
    }


    return (
        <Box sx={mainStyle.root}>
            <Header />
            <Box sx={mainStyle.page}>
                <Box sx={[mainStyle.content, {display: 'flex', flexDirection: 'column', overflow: 'auto', height: '100%', padding: 2, borderRadius: 6, gap: 4}]}>
                    <Box>
                            <Typography 
                                variant='h4'
                                sx={[{display: 'flex', justifyContent: 'center', margin: '2px 4px 2px 4px'}, mainStyle.pageHeader]} 
                            >
                                Opções de acessibilidade
                            </Typography>
                            {(isFetching) ?
                                <Loading />
                            :(roomInfos !== undefined) ?
                                mountRoomAccessibilityOptions(roomInfos)
                            :
                                <Typography sx={{padding: 4, display: 'flex', justifyContent: 'center'}}>
                                    Opções de acessibilidade da sala não encontradas
                                </Typography>
                            }
                    </Box>
                    <Box>
                            <Typography 
                                variant='h4'
                                sx={[{display: 'flex', justifyContent: 'center', margin: '2px 4px 2px 4px'}, mainStyle.pageHeader]} 
                            >
                                Opções de tradução
                            </Typography>
                            {(isFetching) ?
                                <Loading />
                            :(roomInfos !== undefined && roomInfos.translations) ?
                                mountRoomTranslationOptions(roomInfos)
                            :
                                <Typography sx={{padding: 4, display: 'flex', justifyContent: 'center'}}>
                                    Não há salas de tradução
                                </Typography>
                            }
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
