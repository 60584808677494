
import React from 'react';
import { Box, Stack, Typography, Modal, Button } from '@mui/material'

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'
import useIosInstallPrompt from '../../hooks/useIosPrompt';
import useWebInstallPrompt from '../../hooks/useWebInstallPrompt';


type InstallPromptModalProp = {
}

export default function InstallPromptModal(props:InstallPromptModalProp) {
    const [,handleIOSInstallDeclined] = useIosInstallPrompt();
    const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();
    const iosInstallPrompt = false

    return (
        <Modal
            open={iosInstallPrompt || webInstallPrompt}
            aria-label='Criar atalho na tela inicial do dispositivo'
        >
            <Box style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: '#fff',
                border: '2px solid #3F7576',
                borderRadius: 20,
                padding: '20px'
            }}>
                {iosInstallPrompt && (
                    <>
                        <Typography className="text-center">
                            Adicionar um atalho para o Trio App na sua tela inicial?
                        </Typography>
                        <Typography className="text-center">
                            Toque
                            <img
                                src="../../assets/logo.png"
                                style={{ margin: 'auto 8px 8px' }}
                                className=""
                                alt="Add to homescreen"
                                width="20"
                            />
                            então &quot;Adicionar à tela inicial&quot;
                        </Typography>
                        <Box>
                            <Button onClick={handleIOSInstallDeclined} aria-label='Negar adicionar atalho ao aplicativo na tela inicial do dispositivo'>
                                Fechar
                            </Button>
                        </Box>
                    </>
                )}
                {webInstallPrompt && (
                    <Stack spacing={2}>
                        <Typography className="text-center">
                            Adicionar um atalho para o Trio App na sua tela inicial?
                        </Typography>
                        <Stack direction={'row'} flex={1} justifyContent={'space-between'}> 
                            <Button sx={[mainStyle.declineButton]} className='trio-btn' onClick={handleWebInstallDeclined} 
                                aria-label='Negar adicionar atalho ao aplicativo na tela inicial do dispositivo'
                            >
                                Fechar
                            </Button>
                            <Button sx={[mainStyle.acceptButton]} onClick={handleWebInstallAccepted} 
                                aria-label='Confirmar adicionar atalho ao aplicativo na tela inicial do dispositivo'
                            >
                                Adicionar
                            </Button>
                        </Stack>
                    </Stack>
                )}
            </Box>
        </Modal>
    )
}
