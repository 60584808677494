import { useState, useEffect } from 'react'
import {
    Box,
    Link,
    Typography
} from '@mui/material'

// Adapters
import { getEventsAvailable } from '../../adapters/Events.adapter'

// Interfaces
import { IEvents, IEvent } from './Interfaces.events'

// TRIO Components
import EventCard from '../components/EventCard'
import Loading from '../components/Loading'
import Header from '../components/Header'
import { mainStyle } from '../../styles/Index.Styles'
import { SELECTED_DAY } from '../../constants/day'


export default function IndexEvents() {
    const [eventList, setEventList] = useState<Array<IEvents>>([])
    const [fetchingEvents, setFetchingEvents] = useState(false)

    localStorage.removeItem(SELECTED_DAY)

    useEffect(() => {
        setFetchingEvents(true)
        getEventsAvailable()
        .then((res:any) => {
            let events:Array<IEvents> = []
            res.data.events.map((e:IEvent) => {
                const one:IEvents = {
                    uuid: e.id,
                    name: e.event_name,
                    active: e.available,
                    address: e.address,
                    startDate: e.start_date,
                    stopDate: e.stop_date,
                    hasAd: e.hasAd,
                    hasCc: e.hasCc,
                    hasLb: e.hasLb
                }
                events.push(one)
                return one
            })
            setEventList(events)
        })
        .catch((err) => console.error(err))
        .finally(() => {
            setFetchingEvents(false)
        })
    }, [])

    const mountEventsCard = (list:Array<IEvents>) => {
        return list.map((e) => (
                <Link
                    key={e.uuid}
                    href={((e.active === true) ? "/event/" + e.uuid : "#")}
                    color="inherit"
                    underline='none'
                >
                    <EventCard
                        title={e.name}
                        address={e.address}
                        active={e.active}
                        uuid={e.uuid}
                        startDate={e.startDate}
                        stopDate={e.stopDate}
                        hasAd={e.hasAd}
                        hasCc={e.hasCc}
                        hasLb={e.hasLb}
                    />
                </Link>
            )
        )
    }

    return (
        <Box sx={mainStyle.root}>
            <Header />
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                    <Box sx={{overflow: 'auto', height: '100%', padding: .2, borderRadius: 6, gap: '1rem', display: 'flex', flexDirection: 'column'}}>
                        <>
                            {(fetchingEvents) ?
                                <Loading />
                            :(eventList?.length > 0) ?
                                mountEventsCard(eventList)
                            :
                                <Typography sx={{padding: 4, display: 'flex', justifyContent: 'center'}}>
                                    Nenhum evento disponível!
                                </Typography>
                            }
                        </>
                    </Box>
                </Box>
            </Box>
        </Box>
        
    )
}
