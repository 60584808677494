import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material'
import Moment from 'react-moment'

// TRIO Components
import Loading from '../components/Loading'

// Adapters
import { getEventRoomInfo } from '../../adapters/Events.adapter'

// Interfaces
import { IEventRooms } from './Interfaces.events'

// Styles
import { mainStyle } from '../../styles/Index.Styles'
import Header from '../components/Header'

export default function PlayerEvents() {
    const { eventId, roomId } = useParams()
    const [roomInfo, setRoomInfo] = useState<IEventRooms | undefined>(undefined)
    const [sourceLink, setSourceLink] = useState<string[] | undefined>(undefined)
    const [ccLanguage, setCcLanguage] = useState<string>('')
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const type = searchParams.get('type')
    const language = searchParams.get('lang')

    useEffect(() => {
        if (eventId !== undefined && roomId !== undefined) {
            getEventRoomInfo(eventId, roomId)
                .then((res) => {
                    const data = res.data
                    const dataInfo: IEventRooms = {
                        name: data.room_name,
                        description: data.description,
                        event: data.event.event_name,
                        eventUuid: data.event.id,
                        roomUuid: data.id,
                        startDate: new Date(data.start_date),
                        stopDate: new Date(data.stop_date),
                        active: data.available,
                        ad_stream_link: data.ad_stream_link,
                        cc_stream_link: data.cc_stream_link,
                        cc_us_stream_link: data.cc_us_stream_link,
                        lb_stream_link: data.lb_stream_link
                    }
                    let streamLink = ['']
                    switch (type) {
                        case ('ad'):
                            streamLink = [data.ad_stream_link]
                            break
                        case ('cc'):
                            streamLink = [data.cc_stream_link, data.cc_us_stream_link]
                            setCcLanguage(streamLink[0])
                            break
                        case ('lb'):
                            streamLink = [data.lb_stream_link]
                            break
                        case ('translation'):
                            if(data.translations && language) {
                                if(language in data.translations){
                                    streamLink = [data.translations[language]]
                                }
                            }
                            break

                    }
                    setSourceLink(streamLink)
                    setRoomInfo(dataInfo)
                })
                .catch((err) => {
                    console.error(err)
                })
        } else {
            navigate('/')
        }
    }, [eventId, roomId, searchParams, navigate, language, type])

    const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCcLanguage(event.target.value)
    }

    if (sourceLink === undefined)
        return (
            <Loading />
        )

    return (
        <Box sx={mainStyle.root}>
            <Header />
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                    <Box sx={{overflow: 'auto', height: '100%', padding: .2, borderRadius: 6}}>
                        <Box>
                            <Box sx={{padding: 1}}>
                                <Box sx={[mainStyle.flexRow, mainStyle.justifyConent]}>
                                    <Typography
                                        component='span'
                                        sx={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                            fontSize: 26,
                                        }}
                                    >
                                        {roomInfo?.event}
                                    </Typography>
                                </Box>
                                <Box sx={[mainStyle.flexRow, {padding: 1}]}>
                                    <Box>
                                        <Typography
                                            component='span'
                                            sx={{
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontSize: 16
                                            }}
                                        >
                                            Sala:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{paddingLeft: .5}}
                                        >
                                            {roomInfo?.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                {type === 'translation' && 
                                    <Box sx={[mainStyle.flexRow, {padding: 1}]}>
                                        <Box>
                                            <Typography
                                                component='span'
                                                sx={{
                                                    color: '#000',
                                                    fontWeight: 'bold',
                                                    fontSize: 16
                                                }}
                                            >
                                                Língua/Language:
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                sx={{paddingLeft: .5}}
                                            >
                                                {language}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                                <Box
                                    sx={
                                        [
                                            mainStyle.flexRow,
                                            { 
                                                padding: .5, paddingLeft: 1
                                            }
                                        ]
                                    }
                                >
                                    <Box>
                                        <Typography
                                            component='span'
                                            sx={{
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontSize: 16
                                            }}
                                        >
                                            Transmissão:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{paddingLeft: .5}}
                                        >
                                            <Moment date={roomInfo?.startDate} format='DD/MM/YYYY' /> - <Moment date={roomInfo?.startDate} format='HH:mm' /> às <Moment date={roomInfo?.stopDate} format='HH:mm' />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {searchParams.get('type') === 'cc' &&
                                <Box sx={[mainStyle.flexRow, { paddingLeft: 1 }]}>
                                    <Box sx={{paddingLeft: 1}}>
                                        <FormControl>
                                            <FormLabel
                                                sx={{ color: '#000', fontWeight: 'bold' }}
                                                id="language-group-label"
                                            >
                                                Idioma
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="language-group-label"
                                                value={ccLanguage}
                                                name="language-buttons-group"
                                                onChange={handleLanguageChange}
                                            >
                                                <FormControlLabel
                                                    value={sourceLink[0]}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: '#3F7576',
                                                                '&.Mui-checked': {
                                                                    color: '#69C3C4',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label="Português"
                                                />
                                                <FormControlLabel
                                                    value={sourceLink[1]}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: '#3F7576',
                                                                '&.Mui-checked': {
                                                                    color: '#69C3C4',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label="English"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                </Box>
                            }
                            <Box>
                                <iframe
                                    style={{
                                        //display: 'none',
                                        width: '99%',
                                        height: 340,
                                        borderRadius: 10,
                                        padding: 0,
                                        paddingTop: 1,
                                        overflow: 'hidden'
                                    }}
                                    title="Player de audio"
                                    src={
                                        searchParams.get('type') === 'cc'
                                            ? ccLanguage : sourceLink[0]
                                    }
                                    allow="autoplay;"
                                    sandbox='allow-scripts allow-popups allow-same-origin'
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}
