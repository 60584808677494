import React from "react"

import { Button, Typography } from '@mui/material'

import { mainStyle } from '../../styles/Index.Styles'

export default function AudioDescriptionIconButton(props: any) {
    return (
        <Button
            sx={[
                mainStyle.audioDescriptionIconButton,
                props.active?
                    mainStyle.iconButton : mainStyle.disabledIconButton
            ]}
            disableRipple={!props.active}
            onClick={props.onClick}
            {...props}
        >
            <Typography
                align='center'
                style={mainStyle.audioDescriptionIconLabel}
            >
                AD
            </Typography>
        </Button>
    )
}