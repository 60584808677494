import { LocalCaptions } from "../types/LocalCaptions"

export const checkValidCaptionObjetct = (captionObj: LocalCaptions) => {
    if (
        captionObj === undefined ||
        captionObj === null ||
        captionObj?.captions === undefined ||
        captionObj?.captions === null
    ) {
        return false
    }
    return true
}