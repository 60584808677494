import React from 'react'
import { Typography, Box } from '@mui/material'
import { Captions } from '../../types/LocalCaptions'

interface ClosedCaptionProps {
    ccOn: boolean
    captionIndex: number | null
    captions: Captions | undefined
    captionLanguage: string
}

export default function ClosedCaption({
    ccOn,
    captionIndex,
    captions,
    captionLanguage,
}: ClosedCaptionProps) {
    if (!captions) return null
    return (
        <Box>
            <Typography
                variant='h4'
                style={{
                    color: 'white',
                }}
            >
                {ccOn && captionIndex !== null && captionIndex > -1
                    ? captions[captionLanguage][captionIndex].text
                    : ''}
            </Typography>
        </Box>
    )
}
