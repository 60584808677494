import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
    MeetingRoom,
    NoMeetingRoom
} from '@mui/icons-material';

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'

type EventRoomAccessibilityCardProp = {
    active: boolean
    eventUuid: string
    roomUuid: string
    sourceLink: string
    language: string
}

export default function EventRoomTranslationCard(props:EventRoomAccessibilityCardProp) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Box
            sx={[(props.active === true) ? mainStyle.availableAccessibilityCard : mainStyle.unavailableAccessibilityCard]}
        >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
                <Grid spacing={2}>
                    <Typography
                        component='span'
                        sx={{
                            color: '#fff',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        variant={isLargeScreen ? 'h5' : 'h4'}
                        alignSelf={'center'}
                    >
                        {props.language}
                    </Typography>
                    <Box>
                        <Typography
                            component='span'
                            sx={{
                                marginLeft: 1,
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 16
                            }}
                        >
                            {(props.active === true) ? `Disponível` : `Indisponível`}
                        </Typography>
                    </Box>
                </Grid>
                <Box sx={[mainStyle.flexRow, {justifyContent: 'center', alignItems: 'flex-end'}]}>
                    <IconButton
                        sx={[(props.active) ? mainStyle.primaryColor : mainStyle.secondaryColor, mainStyle.iconButton]}
                    >
                        {(props.active === true) ? 
                            <MeetingRoom color='inherit' sx={{fontSize: 42}} />
                        :
                            <NoMeetingRoom color='inherit' sx={{fontSize: 42}} />
                        }
                    </IconButton>
                </Box>
            </Grid>
        </Box>
    )
}
