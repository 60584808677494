
import React, {useState} from 'react';
import { Box, Typography, Modal, Button, TextField } from '@mui/material'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

import { getEventByKey } from '../../adapters/Events.adapter';

// TRIO Style
import { mainStyle, whiteBackground } from '../../styles/Index.Styles'
import { Stack } from '@mui/system';

type EventKeyModalProps = {
    open: boolean
    onClose(): void
}

type EventNotFoundModalProps = {
    open: boolean
    onClose(): void
}

const EventNotFoundModal = ({open, onClose} :EventNotFoundModalProps) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: whiteBackground,
        p: 4,
        borderRadius:'20px'
    }
   return  <Modal aria-labelledby="event-not-found-modal-title" aria-describedby="event-not-found-modal-description" open={open} onClose={onClose}>
    <Stack sx={style} gap={2}>
        <Typography id="event-not-found-modal-title" variant='h4'>Evento não encontrado</Typography>
        <Typography id="event-not-foun-modal-description" variant='body1'>Nenuhm evento com esta palavra chave foi encontrado</Typography>
        <Button onClick={onClose} aria-label='Confirmar' autoFocus={true} sx={mainStyle.acceptButton}>
            Entendi
        </Button>
    </Stack>
</Modal>

}

export default function EventKeyModal(props:EventKeyModalProps) {
    const {open, onClose} = props
    const [inputText, setInputText] = useState('')
    const [showEventNotFoundModa, setShowEventNotFoundModal] = useState(false)
    const navigate = useNavigate()

    const onConfirm = () => {
       getEventByKey(inputText).then((eventRes)=> {
            if (eventRes.status === 200) {
                navigate(`/event/${eventRes.data.id}`)
            }
       }).catch((error) => {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 404) {
                    setShowEventNotFoundModal(true)
                }
            } else {
                console.error(error)
            }
       }) 
    }

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = 
        (event) => setInputText(event.target.value)

    const closeModal = () => {
        setInputText('')
        onClose()
    }

    return (
        <>
        <EventNotFoundModal open={showEventNotFoundModa} onClose={() => {
            setShowEventNotFoundModal(false)
        }}/>
        <Modal
            open={open}
            aria-label='Criar atalho na tela inicial do dispositivo'
            onClose={closeModal}
        >
            <Stack 
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: whiteBackground,
                    borderRadius: 20,
                    padding: '20px'
                }} 
                gap={2}
            >
                <Typography id='event-key-input-label' className="text-center">
                    Acessar evento por palavra chave
                </Typography>
                <TextField label='Palavra chave' autoFocus={true} value={inputText} onChange={handleInputChange}/>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={onConfirm} aria-label='Entrar em evento' sx={mainStyle.acceptButton}>
                        Entrar
                    </Button>
                    <Button onClick={closeModal} aria-label='Cancelar' sx={mainStyle.declineButton}>
                        Cancelar
                    </Button>
                </Box>
            </Stack>
        </Modal>
        </>
    )
}
