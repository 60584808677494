import { Box, IconButton, Grid, Typography } from '@mui/material'
import {
    MeetingRoom,
    NoMeetingRoom
} from '@mui/icons-material';

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'
import { useEffect, useState } from 'react';

type EventRoomCardProp = {
    eventName: string
    roomName: string
    active: boolean
    eventUuid: string
    roomUuid: string
    startDate: Date
    stopDate: Date
    hasAd: boolean
    hasCc: boolean
    hasLb: boolean
}

export default function EventRoomCard(props:EventRoomCardProp) {

    const [roomStartDate, setRoomStartDate] = useState<Date | undefined>(
        undefined
    )
    const [roomEndDate, setRoomEndDate] = useState<Date | undefined>(undefined)
    const [roomDateTime, setRoomDateTime] = useState('')

    useEffect(() => {
        const newStart = props.startDate
        const newEnd = props.stopDate
        if (newStart !== roomStartDate || newEnd !== roomEndDate) {
            setRoomStartDate(newStart)
            setRoomEndDate(newEnd)
            const startString = newStart.toLocaleDateString('pt-br')
            const endString = newEnd.toLocaleDateString('pt-br')
            const startTime = newStart.toLocaleTimeString('pt-br').slice(0, 5)
            const endTime = newEnd.toLocaleTimeString('pt-br').slice(0, 5)
            if (startString !== endString) {
                setRoomDateTime(
                    `${startString} ${startTime}h até ${endString} ${endTime}h`
                )
            } else {
                setRoomDateTime(`${startString} - ${startTime} às ${endTime}h`)
            }
        }
    }, [props.startDate, props.stopDate, roomStartDate, roomEndDate])



    const eventLocal = (
        <Box>
            <Box sx={[mainStyle.flexRow, {paddingTop: 1, alignItems: 'center', height: 90}]}>
                <Box sx={[mainStyle.flexColumn]}>
                    <Typography
                        component='span'
                        sx={{
                            marginLeft: 1,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 14
                        }}
                    >
                        {(props.active === true) ? 'Sala disponível' : 'Sala indisponível'}
                    </Typography>
                    <Typography
                        component='span'
                        sx={{
                            marginLeft: 1,
                            color: '#fff',
                            fontSize: 12
                        }}
                    >
                        {roomDateTime}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )

    return (
        <Box>
            <Box sx={[mainStyle.eventCard, (props.active === true) ? mainStyle.availableEventCard : mainStyle.unavailableEventCard]}>
                <Box>
                    <Typography
                        component='span'
                        sx={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 18
                        }}
                    >
                        {props.eventName}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        component='span'
                        sx={{
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: 14
                        }}
                    >
                        {props.roomName}
                    </Typography>
                </Box>
                 <Box sx={[{display: 'flex', flexDirection: 'row', gap: 2 }]} aria-label='Acessibilidades disponíveis'>
                    {props.hasAd && <Typography sx={[mainStyle.accessibilityTag]} aria-label='Áudio descrição'>AD</Typography>}
                    {props.hasCc && <Typography sx={[mainStyle.accessibilityTag]} aria-label='Closed Captions'>CC</Typography>}
                    {props.hasLb && (
                        <Typography sx={[mainStyle.accessibilityTag]} aria-label='LIBRAS'>LIBRAS</Typography>
                    )}
                </Box>
                <Box sx={[mainStyle.flexRow, {height: 90}]}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            {eventLocal}
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={[mainStyle.flexRow, {justifyContent: 'center', alignItems: 'center', paddingRight: 3, height: 80}]}>
                                <IconButton
                                    sx={[(props.active) ? mainStyle.primaryColor : mainStyle.secondaryColor, mainStyle.iconButton]}
                                >
                                    {(props.active === true) ? 
                                        <MeetingRoom color='inherit' sx={{fontSize: 42}} />
                                    :
                                        <NoMeetingRoom color='inherit' sx={{fontSize: 42}} />
                                    }
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
