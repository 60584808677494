import {
    AxiosResponse,
} from 'axios'

// Settings
import axiosHelper from './Axios.Helper'
import { IEventSingular, IEvent, IEventRoomWithEvent } from '../screens/events/Interfaces.events'
import { LocalCaptions } from '../types/LocalCaptions'

export async function getEventsAvailable(): Promise<AxiosResponse<IEvent[]>> {
    return await axiosHelper({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/client/event`,
    })
}

export async function getEventRoomsAvailable(eventId:string): Promise<AxiosResponse<IEventSingular>> {
    return await axiosHelper({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/client/event/${eventId}`,
    })
}

export async function getEventRoomInfo(
    eventId:string,
    roomId:string
): Promise<AxiosResponse<IEventRoomWithEvent>> {
    return await axiosHelper({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/client/event/${eventId}/room/${roomId}`,
    })
}

export async function getEventByKey(
    eventKey:string,
): Promise<AxiosResponse<IEventSingular>> {
    return await axiosHelper({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/client/event/key/${eventKey}`,
    })
}

export async function getStaticEventCaptions(
    url: string,
): Promise<AxiosResponse<LocalCaptions>> {
    return await axiosHelper({
        method: 'GET',
        url: `${url}`,
    })
}
