import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import {
    SignLanguage,
    ClosedCaption
} from '@mui/icons-material';

import AudioDescriptionIconButton from './AudioDescriptionIconButton';

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'

type AccessibilityType = 'ad' | 'cc' | 'lb'

type EventRoomAccessibilityCardProp = {
    active: boolean
    eventUuid: string
    roomUuid: string
    sourceLink: string
    type: AccessibilityType
}

export default function EventRoomAccessibilityCard(props:EventRoomAccessibilityCardProp) {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const accessibilityLabel = (type: AccessibilityType) => {
        switch(type) {
            case('ad'):
            return 'Audiodescrição'
            case('cc'):
            return 'Closed Caption'
            case('lb'):
            return 'Libras'
        }
    }

    const accessibilityIcon = (type: AccessibilityType) => {
        switch(type) {
            case('ad'):
                return <AudioDescriptionIconButton active={props.active}/>
            case('cc'):
                return <IconButton
                    sx={[(props.active) ? mainStyle.iconButton : mainStyle.disabledIconButton]}
                    disableRipple={!props.active} aria-disabled={!props.active}
                >
                    <ClosedCaption sx={[{fontSize: 42}]}/>
                </IconButton>
            case('lb'):
                return (
                    <IconButton
                        sx={
                            [(props.active) ?
                                mainStyle.iconButton
                                : mainStyle.disabledIconButton
                            ]}
                        disableRipple={!props.active}
                        aria-disabled={!props.active}
                    >
                        <SignLanguage sx={[{fontSize: 42}]}/>
                    </IconButton>
                )
        }
    }

    return (
        <Box
            sx={[(props.active === true) ? mainStyle.availableAccessibilityCard : mainStyle.unavailableAccessibilityCard]}
        >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
                <Grid spacing={2}>
                    <Typography
                        component='span'
                        sx={{
                            color: '#fff',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        variant={isLargeScreen ? 'h5' : 'h4'}
                        alignSelf={'center'}
                    >
                        {accessibilityLabel(props.type)}
                    </Typography>
                    <Box>
                        <Typography
                            component='span'
                            sx={{
                                marginLeft: 1,
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 16
                            }}
                        >
                            {(props.active === true) ? `Disponível` : `Indisponível`}
                        </Typography>
                    </Box>
                </Grid>
                <Box sx={[mainStyle.flexRow, {justifyContent: 'center', alignItems: 'flex-end'}]}>
                    {accessibilityIcon(props.type)}
                </Box>
            </Grid>
        </Box>
    )
}
