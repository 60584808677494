import { useState } from 'react';

import { Box, IconButton, Typography } from '@mui/material'
import {
    SignLanguageRounded,
    ClosedCaptionRounded,
    ArrowBack,
    VpnKey
} from '@mui/icons-material';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import EventKeyModal from './EventKeyModal';

// TRIO Style
import { mainStyle } from '../../styles/Index.Styles'
import { SELECTED_DAY } from '../../constants/day';


export default function Header() {
    const { pathname } = useLocation()
    const { eventId, roomId } = useParams()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [openEventKeyModal, setOpenEventKeyModal] = useState(false)

    const daySelected = localStorage.getItem(SELECTED_DAY)

    const navigateBack = () => {
        if (pathname.includes('player') && !pathname.includes('static_player')) {
            // room player, go back to event rooms
            navigate(`/event/${eventId}/${roomId}`)
            return
        }

        if (eventId && daySelected && !roomId) {
            localStorage.removeItem(SELECTED_DAY)
            navigate(`/event/${eventId}`)
            return
        }

        if (eventId && roomId) {
            navigate(`/event/${eventId}`)
            return
        }

        if (eventId && daySelected === null) {
            navigate('/')
            return
        }
    }

    const customHeaderElement = () => {
        if(pathname.includes('player')) {
            const accessibilityType = searchParams.get('type')
            if(accessibilityType) {
                switch(accessibilityType) {
                    case('ad'):
                        return <Box aria-label='ícone closed captions' sx={[mainStyle.roundIconContainer]}>
                                <Typography fontSize='large' sx={[mainStyle.roundIcon, mainStyle.audioDescriptionIconLabel]}>AD</Typography>
                            </Box>
                    case('cc'):
                        return <Box sx={[mainStyle.roundIconContainer]}>
                                <ClosedCaptionRounded aria-label='ícone closed captions' fontSize='large' sx={[mainStyle.roundIcon]}/>
                            </Box>
                    case('lb'):
                        return <Box sx={[mainStyle.roundIconContainer]}>
                            <SignLanguageRounded aria-label='ícone audiodescrição' fontSize='large' sx={[mainStyle.roundIcon]}/>
                        </Box>
                }
            }
        } else {
            return <IconButton
                size='large'
                sx={mainStyle.iconButton}
                onClick={() => setOpenEventKeyModal(true)}
            >
                <VpnKey />
            </IconButton>
        }
    }

    return (
        <Box style={mainStyle.headerContainer}>
            <EventKeyModal open={openEventKeyModal} onClose={() => setOpenEventKeyModal(false)}/>
            <Box sx={mainStyle.header}>
                <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <Box sx={{padding: 1, width: '33%'}}>
                        {(pathname !== '/') &&
                            <IconButton
                                size='large'
                                sx={mainStyle.iconButton}
                                onClick={navigateBack}
                            >
                                <ArrowBack />
                            </IconButton>
                        }
                    </Box>
                    <Box sx={[mainStyle.logo, {width: '33%'}]}>
                        <Box
                            component="img"
                            sx={{
                                height: 60,
                                width: 60,
                                maxHeight: { xs: 60, md: 60 },
                            }}
                            alt="Logo do APP TRIO"
                            src={require('../../assets/images/logo4x.png')}
                        />
                    </Box>
                    <Box sx={{width: '33%', display: 'flex', justifyContent: 'flex-end'}}>{customHeaderElement()}</Box>
                </Box>
            </Box>
        </Box>
    )
}
