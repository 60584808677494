
// import { createTheme } from '@mui/system'

export const defaultColor = '#3F7576'
export const secondColor = '#A1DADA'
export const whiteBackground = '#FFFF'
export const lightText = '#FFFF'
export const whiteImageBackground = '#EFEFF0'
export const grayBorderColor = '#AFB1B6'
export const lightGreen = '#69C3C4'
export const darkGreen = '#356262'
export const barColor = '#78C9CA'
export const darkText = '#112020'
export const intenseGreen = '#59EB5F'
export const grey = '#8E8E8D'
export const darkGrey = '#515151'
export const lightGrey2 = '#6E6E6E'
export const darkBackground = '#231F20'
export const green = '#0E8E13'
export const red = '#A70A0A'
export const yellow = '#ffff00'
export const lightGrey = '#C6C6C6'
export const darkerGreen = '#1B494A'

// Temporarily using a dict, in the future it will be move to a theme type
export const mainStyle = {
    primaryColor: {
        backgroundColor: '#5FB0B0',
    },
    secondaryColor: {
        backgroundColor: '#4A4A49',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    justifyConent: {
        flexDirection: 'row',
        justifyContent: 'center'
    },
    root: {
        width: '100%',
        height: '100%',
        background: '#D2D2D1',
        display: 'flex',
        flexDirection: 'column'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        minWidth: 340,
        width: '28vw',
        padding: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerContent: {
        display: 'flex',
        alignItems: 'center',
    },
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'auto'
    },
    content: {
        borderRadius: 6,
        minWidth: 340,
        width: '28vw',
        backgroundColor: '#fff',
        padding: 1,
        height: '100%',
        overflow: 'auto'
    },
    eventCard: {
        padding: '20px 10px 20px 10px',
        minHeight: 180,
        borderRadius: 6,
    },
    availableEventCard: {
        backgroundColor: '#3F7576',
        "&:hover": {
            cursor: 'pointer'
        }
    },
    unavailableEventCard: {
        backgroundColor: '#777776',
        "&:hover": {
            cursor: 'not-allowed'
        }
    },
    eventCardTitle: {
        padding: 1,
        height: 40,
    },
    iconButton: {
        backgroundColor: '#69C3C4',
        borderRadius: 10,
        height: 72,
        width: 72,
        padding: 1,
        color: '#1B494A',
        '&:hover': {
            backgroundColor: '#549C9D'
        }
    },
    disabledIconButton: {
        borderRadius: 10,
        height: 72,
        width: 72,
        padding: 1,
        backgroundColor: '#BBBBBB',
        cursor: 'not-allowed',
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: '#BBBBBB',
        }
    },
    acceptButton: {
        backgroundColor: lightGreen,
        color: darkText,
        '&:hover': {
            backgroundColor: darkGreen,
            color: lightText,
        }
    },
    declineButton: {
        color: '#8E8E8D',
        '&:hover': {
            backgroundColor: darkGrey,
            color: lightText
        }
    },
    disabledButton: {
        backgroundColor: '#BBBBBB',
        cursor: 'not-allowed'
    },
    audioDescriptionIconButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    audioDescriptionIconLabel: {
        fontSize: 26,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    roundIconContainer: {
        backgroundColor: '#69C3C4',
        color: '#1B494A',
        width: '72px',
        height: '72px',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    roundIcon: {
        color: '#1B494A',
    },
    availableAccessibilityCard: {
        backgroundColor: '#3F7576',
        padding: 2,
        height: 104,
        borderRadius: 6,
        marginTop: .5,
        "&:hover": {
            cursor: 'pointer'
        }
    },
    unavailableAccessibilityCard: {
        backgroundColor: '#777776',
        padding: 2,
        height: 104,
        borderRadius: 6,
        marginTop: .5,
        "&:hover": {
            cursor: 'not-allowed'
        }
    },
    pageHeader: {
        fontFamily: 'Poppins', 
        color: '#356262',
        fontWeight: 'bold'
    },
    eventDayCardsView: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        textAlign: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    eventDayCard: {
        minWidth: 130,
        height: 90,
        backgroundColor: '#356262',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        cursor: 'pointer',
        ":hover": {
            backgroundColor: '#69C3C4',
            color: '#FFF',
        }
    },
    eventDayCardDate: {
        color: '#fff',
        fontSize: 32,
        fontWeight: 'bold',
    },
    eventDayCardWeekDay: {
        color: '#fff',
        fontSize: 14,
    },
    accessibilityTag: {
        fontWeight: 'bold',
        backgroundColor: darkGreen,
        color: whiteBackground,
        padding: '2px 8px 2px 8px',
    },
}
